import React from "react"

import { Container } from 'reactstrap' 
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-certifications' 
import worldIcon from '../assets/images/world.png'  
import cert1 from '../assets/images/cert1.png'
import cert2 from '../assets/images/cert2.png'
import cert3 from '../assets/images/cert3.png'
import cert4 from '../assets/images/cert4.png'
import cert5 from '../assets/images/cert5.png'
import cert6 from '../assets/images/cert6.png'
import redQuality from '../assets/images/red-quality.png'  
import truckAbsolute from '../assets/images/how.png'
 
export default () => (
  <Layout>
    <SEO title="Certifications" />
    <Slider title="CERTIFICATIONS"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>  
    <div className="certification-background-site" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="redQuality" src={redQuality} alt="red icon quality"/> 
				<h4 className="certH">{'CERTIFICATIONS'}</h4> 
				<p className="certP">We believe in <b>continuous improvement</b></p>  
				<img className="truckAbsolute7" src={truckAbsolute} alt="truck"/> 
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 certdiv">
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert1} alt="service 1"/>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert2} alt="service 2"/> 
						</div>
					</div> 
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert3} alt="service 4"/> 
						</div>
					</div> 
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert4} alt="service 3"/> 
						</div>
					</div> 
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert5} alt="service 4"/>
						</div> 
					</div> 
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert6} alt="service 6"/> 
						</div>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div>    
  </Layout>
)
